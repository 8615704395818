import React from 'react';
import { Logo } from './components/components';
import './App.css';

function App() {
  return (
    <div className="App">
        <Logo></Logo>
    </div>
  );
}

export default App;
