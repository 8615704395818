import './style.sass';

function Logo(props) {
    return (
        <svg className="Logo" width="909" height="170" viewBox="0 0 909 170" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M579.412 47.2V89.544C573.918 79.226 563.6 72.526 550.602 72.526C531.44 72.526 515.896 87.668 515.896 107.5C515.896 127.332 531.44 142.474 550.602 142.474C563.6 142.474 573.918 135.774 579.412 125.456V141H586.112V47.2H579.412ZM551.004 136.042C535.058 136.042 522.596 123.446 522.596 107.5C522.596 91.554 535.058 78.958 551.004 78.958C566.95 78.958 579.412 91.554 579.412 107.5C579.412 123.446 566.95 136.042 551.004 136.042Z" fill="#00C65E"/>
            <path d="M635.585 72.526C615.083 72.526 600.879 87.802 600.879 107.5C600.879 127.734 615.485 142.474 636.121 142.474C649.387 142.474 659.839 136.444 665.333 127.332L659.437 123.848C655.551 130.95 647.243 136.042 636.255 136.042C620.979 136.042 609.455 126.126 607.847 110.984H668.549C668.549 109.912 668.683 108.84 668.683 107.768C668.683 89.41 655.953 72.526 635.585 72.526ZM635.585 78.958C648.717 78.958 660.241 88.07 661.715 104.418H607.847C609.187 89.544 620.309 78.958 635.585 78.958Z" fill="#00C65E"/>
            <path d="M685.032 91.554C685.032 83.514 692.402 78.958 701.112 78.958C708.482 78.958 715.45 82.174 718.264 89.142L723.892 85.926C720.274 77.752 711.966 72.526 701.112 72.526C687.444 72.526 678.332 80.968 678.332 91.554C678.332 115.674 718.934 105.222 718.934 123.446C718.934 131.888 711.028 136.042 701.648 136.042C691.598 136.042 684.362 131.486 681.95 124.25L676.188 127.6C679.404 136.176 688.248 142.474 701.648 142.474C715.986 142.474 725.634 134.434 725.634 123.446C725.634 98.924 685.032 109.51 685.032 91.554Z" fill="#00C65E"/>
            <path d="M743.295 57.786C746.243 57.786 748.655 55.374 748.655 52.426C748.655 49.612 746.243 47.2 743.295 47.2C740.347 47.2 737.935 49.612 737.935 52.426C737.935 55.374 740.347 57.786 743.295 57.786ZM739.945 141H746.645V74H739.945V141Z" fill="#00C65E"/>
            <path d="M825.541 74V89.544C820.047 79.226 809.729 72.526 796.731 72.526C777.569 72.526 762.025 87.668 762.025 107.5C762.025 127.332 777.569 142.474 796.731 142.474C809.729 142.474 820.047 135.774 825.541 125.456V137.784C825.541 153.06 815.625 162.842 798.875 162.842C784.135 162.842 776.631 156.946 773.147 149.844L767.251 153.194C772.075 162.976 783.197 169.274 798.875 169.274C817.367 169.274 832.241 157.884 832.241 137.784V74H825.541ZM797.133 136.042C781.187 136.042 768.725 123.446 768.725 107.5C768.725 91.554 781.187 78.958 797.133 78.958C813.079 78.958 825.541 91.554 825.541 107.5C825.541 123.446 813.079 136.042 797.133 136.042Z" fill="#00C65E"/>
            <path d="M882.371 72.526C872.053 72.526 863.477 76.814 858.385 86.73V74H851.685V141H858.385V105.624C858.385 87.534 868.703 78.958 881.835 78.958C894.431 78.958 901.533 86.998 901.533 99.326V141H908.367V99.326C908.367 83.112 898.317 72.526 882.371 72.526Z" fill="#00C65E"/>
            <path d="M207.544 80.7232C202.375 74.1675 195.17 70.9291 185.85 70.9291C176.922 70.9291 169.325 74.4045 162.902 81.2761C156.637 88.1478 153.426 96.4411 153.426 106.393C153.426 116.345 156.637 124.797 162.902 131.668C169.325 138.54 176.922 141.857 185.85 141.857C195.17 141.857 202.375 138.619 207.544 132.063V139.962H224.774V72.8248H207.544V80.7232ZM202.219 120.057C198.773 123.533 194.387 125.27 189.061 125.27C183.735 125.27 179.349 123.533 175.903 120.057C172.457 116.424 170.734 111.843 170.734 106.393C170.734 100.943 172.457 96.2832 175.903 92.8078C179.349 89.1746 183.735 87.4369 189.061 87.4369C194.387 87.4369 198.773 89.1746 202.219 92.8078C205.821 96.2832 207.544 100.864 207.544 106.393C207.544 111.922 205.821 116.424 202.219 120.057Z" fill="#00C65E"/>
            <path d="M268.477 70.8483C258.217 70.8483 249.68 74.2446 243.18 81.0373C236.679 87.7509 233.312 96.2023 233.312 106.312C233.312 116.501 236.679 124.953 243.336 131.666C249.993 138.38 258.765 141.776 269.808 141.776C282.574 141.776 292.286 136.958 298.864 127.401L285.002 119.345C281.634 123.768 276.622 126.058 270.121 126.058C259.862 126.058 253.596 121.872 251.325 113.421H301.527C301.919 110.893 302.232 108.603 302.232 106.312C302.232 96.2023 299.021 87.9089 292.677 81.0373C286.333 74.2446 278.267 70.8483 268.477 70.8483ZM251.09 100.073C253.126 91.4632 259.313 86.4872 268.555 86.4872C276.387 86.4872 283.044 90.9103 285.08 100.073H251.09Z" fill="#00C65E"/>
            <path d="M363.166 81.1181C358.075 74.2465 351.026 70.9291 341.863 70.9291C332.543 70.9291 324.711 74.3255 318.289 81.0392C311.867 87.7528 308.734 95.8093 308.734 105.366C308.734 114.924 311.945 123.059 318.211 129.852C324.633 136.407 332.465 139.804 341.941 139.804C351.105 139.804 358.153 136.407 363.244 129.615V136.091C363.244 146.675 356.587 152.599 345.779 152.599C337.555 152.599 331.682 149.519 328.314 143.2L313.668 151.651C319.542 162.393 330.82 168.001 345.231 168.001C354.786 168.001 362.931 165.316 369.745 159.787C376.558 154.258 380.004 146.359 380.004 136.012V72.8248H363.244V81.1181H363.166ZM344.448 123.691C333.64 123.691 325.808 116.029 325.808 105.287C325.808 100.074 327.531 95.6513 330.977 92.255C334.579 88.7796 338.965 87.042 344.448 87.042C349.93 87.042 354.316 88.7796 357.762 92.255C361.364 95.6513 363.087 100.074 363.087 105.287C363.166 116.029 355.256 123.691 344.448 123.691Z" fill="#00C65E"/>
            <path d="M409.999 84.3551V72.7443H392.847V139.881H409.999V107.813C409.999 101.337 412.035 96.8346 416.108 93.9912C420.258 91.1477 425.036 90.1209 430.205 90.7528V71.4016C421.198 71.4016 413.21 75.7458 409.999 84.3551Z" fill="#00C65E"/>
            <path d="M494.739 81.1952C487.926 74.3236 479.546 70.8483 469.677 70.8483C459.809 70.8483 451.429 74.3236 444.615 81.1952C437.802 88.0669 434.512 96.3603 434.512 106.312C434.512 116.264 437.88 124.716 444.615 131.587C451.351 138.459 459.809 141.776 469.677 141.776C479.546 141.776 487.926 138.38 494.739 131.587C501.553 124.795 504.999 116.264 504.999 106.312C504.999 96.3603 501.553 88.0669 494.739 81.1952ZM482.6 119.661C479.154 123.136 474.846 124.874 469.677 124.874C464.508 124.874 460.201 123.136 456.755 119.661C453.387 116.185 451.664 111.762 451.664 106.391C451.664 101.02 453.387 96.5972 456.755 93.1219C460.201 89.6466 464.508 87.9089 469.677 87.9089C474.846 87.9089 479.154 89.6466 482.6 93.1219C486.046 96.5972 487.769 101.02 487.769 106.391C487.769 111.762 486.046 116.185 482.6 119.661Z" fill="#00C65E"/>
            <path d="M72.1317 0H11.043V5.6079C11.043 24.6432 21.5377 41.1509 36.9665 49.7602C15.2722 59.1594 0 80.9591 0 106.313C0 140.355 27.3333 167.921 61.0887 167.921C81.7649 167.921 100.013 157.574 111.056 141.698V162.313H122.177V50.3921C122.099 22.5896 99.7783 0 72.1317 0ZM61.0887 156.705C33.5205 156.705 11.1213 134.116 11.1213 106.313C11.1213 78.5106 33.4422 56 61.0887 56C88.657 56 111.056 78.5896 111.056 106.392C111.056 134.195 88.657 156.705 61.0887 156.705ZM110.978 71.0071C99.9349 55.2102 81.6866 44.7842 61.0104 44.7842C41.4307 44.7842 25.2187 30.1721 22.5558 11.2158H72.1317C93.5911 11.2158 110.978 28.7504 110.978 50.3921V71.0071Z" fill="#00C65E"/>
        </svg>

    );
}

export default Logo;